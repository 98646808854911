import axios from 'axios'
import {getToken} from "./token";
import {BaseUrl} from "./baseUrl";
//http://43.136.14.72:8848
const baseURL ='https://base-online-dzjbcplpft.cn-hangzhou.fcapp.run'
// http://192.168.31.20:8848
const request = axios.create({
    baseURL:baseURL,//基准地址
    timeout:5000,
})
const token = getToken()

//拦截请求
request.interceptors.request.use((config)=>{
    config.headers["Content-Type"] = 'application/json'
    if (token){
        config.headers['Token'] = token
    }
    return config
})
//拦截响应
request.interceptors.response.use((response)=>{
        return response
    },function (error){
        //对响应的错误做点什么
        return Promise.reject(error);
    }
)

export default request
