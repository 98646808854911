import React, {useState} from 'react';
import styled from "styled-components";
import FlexBox from "../../../commen/FlexBox";
import {Input, Toast} from "antd-mobile";
import CountDown from "../../../commen/CountDown";
import {getLoginCode, loginCallback} from "../../../services";
import {checkPhoneNumber} from "../../../utils";
import {setToken} from "../../../utils/token";
import {useNavigate} from "react-router";
import {useLocation} from "react-router-dom";

const Container = styled.div`
    margin: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`
const Title = styled.div`
    font-size: 1.25rem;
    color: #000000;
    margin-top: 1rem;
`
const Logo = styled.img`
    width: 4rem;
    height: 4rem;
    border-radius: 0.5rem;
    margin-top: 8rem;
`
const InputTip = styled.div`
    font-size: 1rem;
    color: #000000;
    margin-right: 0.25rem;
    width: 3.75rem;
    text-align: right;
`
const LoginBtn = styled.div`
    margin-top: 3rem;
    background-color: ${props => props.bg};
    border: none;
    color: #FFFFFF;
    width: 12rem;
    height: 2.25rem;
    border-radius: 1rem;
    font-size: 1rem;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
	cursor: pointer;
`
const ComLogin = () => {
	const [phone, setPhone] = useState('')
	const [startTimer, setStartTimer] = useState(false)
	const [code, setCode] = useState('')
	const navigate = useNavigate()
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const dealerCode = searchParams.get('dealerCode')
	const getCode = () => {
		if (phone === '') {
			return Toast.show({
				icon: 'fail',
				content: '请输入手机号',
			})
		}
		if (!checkPhoneNumber(phone)) {
			return Toast.show({
				icon: 'fail',
				content: '请检查手机号是否正确',
			})
		}
		getLoginCode({acc: phone, loginType: 'login_phone',debug: true}).then(res => {
			if (res.data.isSucc) {
				setStartTimer(true)
				Toast.show({
					icon: 'success',
					content: '已发送验证码,请注意查收',
				})
			}
		})
	}
	const login = () => {
		if (!startTimer) {
			return;
		}
		if (code === '') {
			return Toast.show({
				icon: 'fail',
				content: '请输入验证码',
			})
		}
		const reg = /^\d/
		if (!reg.test(code)) {
			return Toast.show({
				icon: 'fail',
				content: '验证码错误',
			})
		}
		loginCallback({acc: phone, cb: code, loginType: 'login_phone',dealerCode:dealerCode}).then(res => {
			if (res.data.isSucc) {
				if (dealerCode){
					Toast.show({
						icon: 'success',
						content: '注册成功,即将跳转至小卡下载页',
					})
					setTimeout(() => {
						window.location.href ='https://www.xktruck.com/index.html'
					},2000)
					
				}else {
					setToken(res.data.res.__token)
					navigate('/community', {replace: true})
					Toast.show({
						icon: 'success',
						content: '登录成功',
					})
				}
				
				
			}
		})
	}
	return (
		<Container>
			<Logo src={require('../../../assets/images/base/xk_icon.png')}/>
			<Title>{dealerCode?'注册小卡':'小卡论坛'}</Title>
			<FlexBox style={{marginTop: "2rem", width: "20.5rem"}}>
				<InputTip>账号:</InputTip>
				<Input maxLength={11}
				       style={{border: "1px solid #B3B3B3", borderRadius: "0.5rem", padding: "0.25rem",flex:1,height:"1.25rem"}}
				       value={phone} onChange={(e) => setPhone(e)} type={'text'} placeholder={'请输入手机号'}/>
			</FlexBox>
			<FlexBox style={{marginTop: "2rem", width: "20.5rem"}}>
				<InputTip>验证码:</InputTip>
				<FlexBox style={{
					justifyContent: "space-between",
					border: "1px solid #B3B3B3",
					borderRadius: "0.5rem",
					flex:1
				}}>
					<Input  style={{padding: "0.25rem",height:"1.25rem"}} maxLength={4} value={code} onChange={(e) => setCode(e)} type={'text'}
					       placeholder={'请输入验证码'}/>
					<CountDown style={{
						width: "6rem",
						height: "1.25rem",
						borderRadius: "0.375rem",
						textAlign: 'center',
						lineHeight: "1.25rem",
						fontsize:"1rem",marginRight:"0.25rem",
						cursor:"pointer"
					}} onClick={getCode} status={startTimer}/>
				</FlexBox>
			</FlexBox>
			<LoginBtn bg={(phone !== '' && code !== '') ? '#7A57EB' : '#C3C3C3'} onClick={login}>{dealerCode?'注册':'登录'}</LoginBtn>
		</Container>
	);
};

export default ComLogin;
